import React from 'react'
import useLocationList from '../../hooks/locationList'

const SelectLocation = ({ userLocation, setUserLocation }) => {
  const locations = useLocationList()

  const handleChange = (e) => {
    setUserLocation(e.target.value)
    console.log(`Location accepted ${e.target.value}`)
  }
  return (
    <>
      <div className="select-wrap">
        <select
          name="locations"
          id="locations"
          onChange={(e) => {
            handleChange(e)
          }}
          value={userLocation ? userLocation : ''}
        >
          <option value="" disabled>
            Select a location
          </option>
          {locations.map(({ name, description }) => {
            if (!name.includes('Online')) {
              return (
                <option key={description} value={description}>
                  {name}
                </option>
              )
            }
          })}
        </select>
      </div>
    </>
  )
}

export default SelectLocation
