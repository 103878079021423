import React from 'react'
import Provider from '../utils/GlobalContext'
import { Header } from './Header'
import { Footer } from './Footer'

const Layout = ({ path, children, title, location }) => {
  return (
    <Provider>
      <Header path={path} location={location} />
      <title>{title}</title>
      <main className="container">{children}</main>
      <Footer />
    </Provider>
  )
}

export default Layout
