import React from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { StyledDrawer } from './Drawer.styles'

const Drawer = ({ children, anchor, state, setState }) => {
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <SwipeableDrawer
      anchor={anchor}
      className={anchor ? anchor : ``}
      open={state}
      onClose={() => {
        setState(false)
      }}
      onOpen={() => {
        setState(true)
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <StyledDrawer className={anchor}>{children}</StyledDrawer>
    </SwipeableDrawer>
  )
}

export default Drawer
