import React from 'react'
import { Drawer } from '../Drawer'
import { SelectLocation } from '../SelectLocation'
import { Submit } from '../Submit'
import { StyledUnknownCheckin } from './UnknownCheckin.styles'

const UnknownCheckin = ({
  unknownActive,
  setUnknownActive,
  userLocation,
  setUserLocation,
  search,
}) => {
  return (
    <Drawer
      anchor="bottom"
      state={unknownActive}
      setState={setUnknownActive}
      userLocation={userLocation}
    >
      <StyledUnknownCheckin>
        <h3>No problem!</h3>
        {!search ? (
          <>
            <p>Please select a location and enter your first name only.</p>
            <SelectLocation setUserLocation={setUserLocation} />
          </>
        ) : (
          <p>For privacy reasons, please enter your first name only.</p>
        )}
        <Submit />
      </StyledUnknownCheckin>
    </Drawer>
  )
}

export default UnknownCheckin
