import styled from 'styled-components'

export const StyledLoader = styled.div`
  width: 30px;
  margin: 0 auto;
  height: auto;

  &.checkin {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 25px;

    @media (max-width: 600px) {
      bottom: 40px;
    }
  }

  &.unknown {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 42px;

    @media (max-width: 600px) {
      right: 55px;
    }
  }

  img {
    width: 30px;
    height: auto;
  }
`
