import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import { GlobalContext } from '../../utils/GlobalContext'
import { Loader } from '../Loader'
import { StyledSubmit } from './Submit.styles'

const Submit = ({ clinician }) => {
  const {
    register,
    handleSubmit,
    setError,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm()

  const {
    userTel,
    userName,
    setUserName,
    userLocation,
    unknownActive,
    setUnknownActive,
    setTherapistSelected,
  } = useContext(GlobalContext)

  // const GATEWAY_URL = `${process.env.GATEWAY_URL}`
  const GATEWAY_URL = `https://9xwjapqoe1.execute-api.us-west-2.amazonaws.com/Checkin`

  // submission object
  let submissionData = {}
  if (unknownActive) {
    submissionData = {
      checkin: true,
      record: {
        Type__c: 'Unknown',
        Location__c: userLocation,
        First_Name__c: userName,
        Phone_Number__c: userTel,
        Unknown_Checkin_Name__c: userName,
      },
    }
  } else {
    submissionData = {
      checkin: true,
      record: {
        Type__c: 'Known',
        Location__c: userLocation,
        Clinician__c: clinician,
        First_Name__c: userName,
        Phone_Number__c: userTel,
      },
    }
  }

  const handleChange = (e) => {
    setUserName(e.target.value)
  }

  const handleSuccess = () => {
    setUnknownActive(false)
    setTherapistSelected(false)
    navigate(`/success?location=${userLocation}`)
  }

  const onSubmit = async (data) => {
    if (data.phone) {
      submissionData.record.Phone_Number__c = data.phone
    }
    if (data.firstName) {
      submissionData.record.Unknown_Checkin_Name__c = data.firstName
    }
    try {
      const getResponse = await fetch(GATEWAY_URL, {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        body: JSON.stringify(submissionData),
        headers: {
          'Content-type': 'application/json',
        },
      })
      // wait for response from server
      const testResponse = await getResponse
      // ok returns true if status 200
      testResponse.ok && console.log(testResponse)
      handleSuccess()
      reset()
    } catch (error) {
      setError(
        'submit',
        'submitError',
        `There seems to be a problem! Code: ${error.message}`
      )
    }
  }

  const showSubmitError = (msg) => <p className="msg-error">{msg}</p>

  return (
    <StyledSubmit>
      {errors && errors.submit && showSubmitError(errors.submit.message)}
      <form className="" method="post" onSubmit={handleSubmit(onSubmit)}>
        <input
          id="name"
          {...register('firstName')}
          type="text"
          placeholder="Your First Name"
          minLength="1"
          onChange={(e) => handleChange(e)}
        />

        <input
          type="submit"
          value={isSubmitting ? `` : `Check-in`}
          disabled={!userTel || !userName || !userLocation || isSubmitting}
        />
      </form>
      {isSubmitting && (
        <Loader className={unknownActive ? `unknown` : `checkin`} />
      )}
    </StyledSubmit>
  )
}

export default Submit
