import styled from 'styled-components'

export const StyledMenu = styled.div`
  position: relative;

  .logo {
    width: 120px;
    margin: 0 auto 1rem;
    display: block;
  }

  span,
  a:not(.tel) {
    display: flex !important;
    align-items: center;
    background-color: var(--color__neutral--100);
    color: var(--color__blue--900);
    font-weight: bold;
    line-height: 1.3;
    padding: 13px;
    border-radius: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    height: auto;
    text-align: left;
    letter-spacing: -0.03em;

    &:hover {
      background-color: var(--color__neutral--200);
    }

    svg {
      width: 24px;
      margin-right: 10px;
    }
  }

  p {
    margin: 2rem 0 0;
    text-align: center;
  }
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 22px;
  left: -30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23fff' viewBox='0 0 24 24'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18 18 6M6 6l12 12'/%3E%3C/svg%3E");
  height: 24px;
  width: 24px;
`
