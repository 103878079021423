import React, { useState, createContext, useEffect } from 'react'

const GlobalContext = createContext()

const Provider = ({ children }) => {
  const [userTel, setUserTel] = useState('')
  const [userName, setUserName] = useState('')
  const [userLocation, setUserLocation] = useState('')
  const [menuActive, setMenuActive] = useState(false)
  const [therapistSelected, setTherapistSelected] = useState(false)
  const [unknownActive, setUnknownActive] = useState(false)

  useEffect(() => {
    setUserTel(window.localStorage.getItem('userTel'))
    setUserLocation(window.localStorage.getItem('userLocation'))
  }, [])

  useEffect(() => {
    window.localStorage.setItem('userTel', userTel)
  }, [userTel])

  useEffect(() => {
    window.localStorage.setItem('userLocation', userLocation)
  }, [userLocation])

  return (
    <GlobalContext.Provider
      value={{
        userTel,
        setUserTel,
        userName,
        setUserName,
        userLocation,
        setUserLocation,
        menuActive,
        setMenuActive,
        therapistSelected,
        setTherapistSelected,
        unknownActive,
        setUnknownActive,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export { Provider as default, GlobalContext }
