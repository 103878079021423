import styled from 'styled-components'

export const StyledHeader = styled.header`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;

  span {
    display: block;
    margin: 0 auto;
    cursor: pointer;

    svg {
      max-width: 100px;
      width: 100%;
    }
  }
`

export const Overlay = styled.div`
  position: fixed;
  background-color: var(--color__blue--900);
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 997;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

  &.is-active {
    opacity: 0.9;
    visibility: visible;
  }
`
