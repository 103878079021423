import styled from 'styled-components'

export const StyledUnknownCheckin = styled.div`
  text-align: center;
  padding: 0 0 1rem;

  @media (max-width: 560px) {
    padding: 1rem 0 2rem;
  }

  h3 {
    margin: 2rem 0 0;
    font-size: var(--fontSize__h4);
  }

  .select-wrap {
    margin-bottom: 10px;
  }
`
