import { useStaticQuery, graphql } from 'gatsby'

export default function useLocationList() {
  const data = useStaticQuery(graphql`
    {
      allWpTherapist(sort: { fields: title }) {
        edges {
          node {
            therapistLocations {
              nodes {
                name
                description
              }
            }
          }
        }
      }
    }
  `)

  // map therapists
  const locationArr = data.allWpTherapist.edges.map(({ node }) =>
    // map locations
    node.therapistLocations.nodes.map((location) => location)
  )

  // flatten arrays
  const flatArr = locationArr.flat()

  // map id's
  const ids = flatArr.map((item) => item.description)

  // remove duplicate id's
  const locations = flatArr.filter(
    ({ description: id }, index) => !ids.includes(id, index + 1)
  )

  // sort alphabetically
  locations.sort((a, b) => a.name.localeCompare(b.name))

  return locations
}
