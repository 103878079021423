import React from 'react'
import { StyledFooter } from './Footer.styles'

const Footer = () => (
  <StyledFooter className="container">
    <p>
      Need help? <br />
      Click the menu on the top-right of the screen.
    </p>
  </StyledFooter>
)

export default Footer
