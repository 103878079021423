import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  body {
    font-size: var(--fontSize__base--sm);
    color: var(--color__neutral--600);
    height: calc(100vh - calc(100vh - 100%))
  }

  .container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
    max-width: 800px;
    width: 100%;
  }

  h1 {
    font-size: var(--fontSize__h3);
  }

  h2 {
    font-size: var(--fontSize__h4);
  }

  h3 {
    font-size: var(--fontSize__h5);
  }

  a {
    text-decoration: none;
    color: var(--color__blue--500);
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  input,
  .select-wrap {
    font-size: var(--fontSize__base--sm);
    background-color: var(--color__neutral--100);
    border: none;
    outline: 0;
    display: block;
    width: 100%;
    border-radius: 8px;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--color__neutral--200);
    }
  }

  .select-wrap {
    position: relative;

    &::after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 9'%3E%3Cpath stroke='%232680C2' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15 1 8 8 1 1'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      width: 14px;
      height: 7px;
      position: absolute;
      top: 21px;
      right: 20px;
      cursor: pointer;
    }
    
    select {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      appearance: none;
      background: none;
      border: none;
      outline: 0;
      padding: 0 20px;
      cursor: pointer;
    }
  }

  .hidden {
    display: none;
  }

  input[type="submit"],
  button,
  a.button,
  .button {
    background-color: var(--color__blue--500);
    border-color: var(--color__blue--500);
    color: #fff;
    font-weight: 600;
    border-radius: 8px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s ease;
    padding-top: 0;
    padding-bottom: 0;

    &:hover {
      background-color: var(--color__blue--600);
    }

    &.bigly {
      display: block;
      width: 100%;
    }

    &.icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 21px;
        width: 21px;
      }
    }

    &[disabled] {
      background: #B7C5D2;
      border-color: #B7C5D2 !important;
      pointer-events: none;
    }
  }

  form.joined {
    display: grid;
    grid-template-columns: 1fr 108px;
    
    input:not([type="submit"]) {
      border-radius: 8px 0 0 8px;
    }

    input[type="submit"] {
      border-radius: 0 8px 8px 0;
      font-size: 16px;
    }
  }

  .box-error {
    background-color: var(--color__secondary-red--100);
    color: var(--color__secondary-red--900);
    border-radius: 10px;
    padding: 2rem 20px;
  }

  /* mui overrides */
  /* drawer */
  .MuiDrawer-paper {
    max-width: 400px;
    width: calc(100% - 40px);
    margin: 0 auto;
    background: none !important;
    box-shadow: none !important;
  }

  .MuiDrawer-paperAnchorRight {
    width: 400px;
    max-width: 80%;
    overflow-y: visible !important;
  }

  /* modal */
  .MuiDrawer-modal {
    height: 0;
  }

  /* backdrop */
  .MuiBackdrop-root {
    display: none !important;
  }
`

export default GlobalStyle
