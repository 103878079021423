import React from 'react'
import icon from '../../images/loader.svg'
import { StyledLoader } from './Loader.styles'

const Loader = ({ className }) => {
  return (
    <StyledLoader className={className}>
      <img className="loader" src={icon} alt="Loading..." />
    </StyledLoader>
  )
}

export default Loader
