/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import Provider from './src/utils/GlobalContext'

// export const wrapRootElement = Provider

import React from 'react'
import 'glpg-reset'
import Layout from './src/components/layout'
import GlobalStyle from './src/styles/global'

export function wrapPageElement({ element, props }) {
  return (
    <>
      <GlobalStyle />
      <Layout {...props}>{element}</Layout>
    </>
  )
}
