import styled from 'styled-components'

export const StyledDrawer = styled.div`
  background-color: #fff;
  padding: 1rem;

  &.bottom {
    border-radius: 20px 20px 0 0;
    margin: 0 auto;
    width: 100%;

    &::before {
      content: '';
      height: 4px;
      border-radius: 2px;
      width: 70px;
      margin: 0 auto;
      display: block;
      background: var(--color__neutral--200);
      position: absolute;
      top: 1rem;
      right: 0;
      left: 0;
    }
  }

  &.right {
    height: 100%;
  }
`
